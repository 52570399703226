export const environment = {
    production: false,
    cmsUrl: 'https://dna-web-testing.azurewebsites.net/redirected?token=',
    apiUrlRoot: "https://dna-api-master-testing.azurewebsites.net",
    apiUrl: "https://dna-api-master-testing.azurewebsites.net/api",
    signalrUrl: "https://signalr-develop.digitalnewsagency.com:5002",
    mediaUrl: "https://dnamediastoragetesting.blob.core.windows.net/",
    facebookAppId: "137527330414505",
    facebookAppSecret: "9142cc75dfbbe38e5a8104c057fc7bea",
    algoliaApplicationId: "0KMDPEZ84J",
    algoliaApiKey: "e44d0540379aa8b931318b3275dc2b7e",
    algoliaStoriesIndex: 'testing_stories',
    algoliaMediaIndex: 'testing_media',
    algoliaStoriesAsc: 'testing_stories_storyTimestamp_asc',
    algoliaStoriesTitleAsc : 'testing_stories_title_asc',
    algoliaStoriesTitleDesc : 'testing_stories_title_desc',
  };
  