<div class="popup-background">
  <div class="popup-overlay"></div>
  <div class="popup">
    <!--<div class="close-icon close-popup-icon" *ngIf="token || !popupModelResponse?.mustRegisterToAccess" (click)="closePopup()"></div>-->
    <div class="close-icon close-popup-icon" (click)="closePopup()"></div>
    <div>
      <img class="featured-image-popup" src="{{ popupModelResponse?.media?.thumbUrl }}"
        *ngIf="popupModelResponse?.media?.mediaType != 'Video' && popupModelResponse.media.extension == 'pdf'" alt="{{ popupModelResponse?.media?.name }}" />

        <img class="featured-image-popup" src="{{ popupModelResponse?.media?.thumbUrl }}"
        *ngIf="popupModelResponse?.media?.mediaType != 'Video' && popupModelResponse.media.extension != 'pdf'" alt="{{ popupModelResponse?.media?.name }}" style="background-color: grey; height: 400px;"/>


      <video class="featured-image-popup" *ngIf="popupModelResponse?.media?.mediaType === 'Video' && !autoplay" controls
        controlsList="nodownload" oncontextmenu="return false;">
        <source src="{{ playerSource }}" type="video/mp4" />
        {{ language.unsupportedVideoBrowser }}
      </video>

      <video class="featured-image-popup" *ngIf="popupModelResponse?.media?.mediaType === 'Video' && autoplay" controls
        autoplay controlsList="nodownload" oncontextmenu="return false;">
        <source src="{{ playerSource }}" type="video/mp4" />
        {{ language.unsupportedVideoBrowser }}
      </video>

      <audio class="featured-image-popup" *ngIf="popupModelResponse?.media?.mediaType === 'Audio'" controls
        controlsList="nodownload" oncontextmenu="return false;">
        <source src="{{ playerSource }}" type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </div>
    <div class="popup-content-wrapper">
      <div class="popup-content">
        <div class="action-buttons flex">
          Share file to:
          <!-- <app-share-5 *ngIf="urlForShare" [image]="popupModelResponse?.media?.thumbUrl" [big]="true" [vertical]="false" [isPublished]="isPublished"
            [open]="true" [noTitle]="true" [shared]="popupModelResponse?.media?.shares"
            (emitShared)="updateSharedNumber($event)" [sbUrl]="urlForShare"
            [title]="popupModelResponse?.media?.shareTitle"
            [description]="popupModelResponse?.media?.shareShortDescription" [mediaId]="mediaId"
            [webNewsDetailsResponse]="webNewsDetailsResponse" [isMediaShare]="true" [isMediaShareBig]="true"
            (embeddOpen)="openEmbeddedPopup()" [socials]="popupModelResponse?.socialSharing">
          </app-share-5> -->
            
          <!-- <div class="story-share" *ngIf="webNewsDetailsResponse"> -->
            <div class="share-buttons">
              <ng-container *ngFor="let social of socials">
                <div class="popup-share-button-container">
                  <app-share-button-5 class="share-button" [image]="popupModelResponse?.media?.thumbUrl" [sbUrl]="urlForShare"
                  [title]="popupModelResponse?.media?.shareTitle" [description]="popupModelResponse?.media?.shareShortDescription"
                    [storyId]="popupModelResponse?.media?.storyId" [mediaId]="mediaId"
                    [staggingStoryId]="popupModelResponse?.media?.staggingStoryId" [social]="social" [open]="share"
                    [shared]="popupModelResponse?.media?.shared">
                  </app-share-button-5>
                </div>
              </ng-container>
            </div>
          <!-- </div> -->
            
        </div>
        <div class="popup-headline">
          {{ popupModelResponse?.media?.name | replace : "_" : " " }}
        </div>

        <ul class="download-format flex-center">
          <div class="file-type-total-container" *ngFor="let popup of popupModelResponse?.downloadLinks">
            <li class="file flex-center" *ngIf="
                popup.label.includes('ORIGINAL') ||
                popup.label.includes('ORGINAL') ||
                popup.label.includes('XLSX') ||
                popup.label.includes('TXT') ||
                popup.label.includes('PPTX') ||
                popup.label.includes('DOCX') ||
                popup.label.includes('PDF')
              ">
              <div class="file-type flex">
                <span>{{ popup.label }}</span>
              </div>
              <div class="download-information flex-end">
                <button *ngIf="!popup.downloadActive" [ngClass]="[disabledButton ? 'disabledButton' : '']"
                  class="generic-button flex-center" (click)="download(popup)">
                  {{ language.download }}
                </button>
                <div class="flex" *ngIf="popup.downloadActive">
                  <!-- <button class="download-button flex-center" (click)="cancelDownload(popup)">
                    {{ language.cancel }}
                  </button> -->
                  <div class="cancel-download-loader-wrap">
                    <app-loader></app-loader>
                  </div>
                </div>
              </div>
            </li>
          </div>
        </ul>
        <div class="availability" *ngIf="popupModelResponse.alsoIn">
          <div>{{ language.alsoAvailableIn }}:</div>
          <div *ngIf="popupModelResponse?.alsoInStoryLink" class="headline">
            <a class="unchangedLink" (click)="goToStory()">
              {{ popupModelResponse?.alsoInTitle }}</a>
          </div>
          <hr />
          <div>{{ popupModelResponse?.alsoInShortDescription }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-loader *ngIf="loader"></app-loader>
<app-loginpopup *ngIf="loginPopup" [(loginPopup)]="loginPopup" (loginSuccessfulChange)="refreshMediaLinks()">
</app-loginpopup>