import {
  Component,
  ElementRef,
  HostListener,
  Input,
  NgZone,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NewsModel } from "../../models/news/news.model";
import { McService } from "../../shared/services/mc-service";

import {
  FeaturedStoriesModel,
  HomeModel,
  LatestMediaModel,
  LatestStoriesModel,
} from "./home-model";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { TokenManagerService } from "../../shared/services/token-manager.service";
import { StorageService } from "../../shared/services/storage.service";
import { UrlHelper } from "../../shared/helpers/url-helper";
import { PageStyle } from "../../models/style/style.model";
import { Language } from "../../models/language/language.model";
import { SavedStoryService } from "../../shared/services/saved-story.service";
import { Meta } from "@angular/platform-browser";
import { Title } from "@angular/platform-browser";
import { FontsAndColorsService } from "../../shared/services/fonts-and-colors.service";
import { LanguageService } from "../../shared/services/language.service";
import { Location } from "@angular/common";
import { WebNewsModel, WebNewsDetailsMediaModel, WebNewsDetailsModel } from "../../routes/news/news-model";

declare var $: any;

import { MetaModel } from "../../models/meta/meta";
import { FacebookService } from "ngx-facebook";
import { environment } from "../../../environments/environment";
import { GoogleAnalyticsEventsService } from "../../shared/services/google-analytics-events.service";
import { CommunicationService } from "../../shared/services/communication.service";
import { MCLanguageService } from "../../shared/services/mc-language.service";
import { ButtonsHelper } from "../../shared/helpers/buttons-helper";
import { WebpHelperService } from "../../shared/services/webp-helper.service";
import { BrandsManageModel } from "../brands/brands-model";
// import { HomeModel } from "home-model";

@Component({
  selector: "app-homepage-5",
  templateUrl: "./homepage-5.component.html",
  styleUrls: ["./homepage-5.component.less"],
})

export class HomepageComponent5 implements OnInit {
  @Input() story: LatestStoriesModel | WebNewsModel;
  news: NewsModel[];
  picture: SafeHtml;
  visibleNews: NewsModel[];
  imageMedia: WebNewsDetailsMediaModel[] = [];
  webNewsDetailsResponse: WebNewsDetailsModel;
  searchText = "";
  dropdown = false;
  grid: string;
  latestCategoryGrid: string;
  activeSubTab: number;
  filtered: LatestMediaModel[] = [];
  category: string;
  mediaId: number;
  popup = false;
  p = 1;
  subcategory = false;
  twitterActive: boolean;
  facebookActive: boolean;
  instagramActive: boolean;
  linkedinActive: boolean;
  loader: boolean;
  image: string;
  audio: number;
  video: number;
  document: number;
  images: number;
  twitter: any[];
  facebook: any[];
  linkedin: any;
  homeModelResponse = new HomeModel();
  storyGroupings: LatestStoriesModel[][] = [[]];
  items: Array<any> = [];
  backgroundUrl = "";
  videoUrl = "";
  thumbUrl = "";
  autoplay: boolean;
  selectedFs: any = {};
  activeSlide: number = 0;
  all = true;
  imageTab = false;
  videoTab = false;
  documentTab = false;
  audioTab = false;
  styles = new PageStyle();
  showVideos = false;
  showImages = false;
  showAudios = false;
  showDocuments = false;
  countAll = 0;
  countVideos = 0;
  countImages = 0;
  countAudios = 0;
  countDocuments = 0;
  user: any;
  activeAllTab = false;
  activeTab = false;
  activeImageTab = false;
  activeVideoTab = false;
  activeDocumentsTab = false;
  activeAudioTab = false;
  socials: string[] = [];
  currentUrl: string;
  activeAll: boolean = true;
  activeImage: boolean = false;
  activeVideo: boolean = false;
  activeDoc: boolean = false;
  activeAudio: boolean = false;
  fStories: FeaturedStoriesModel[];
  nextFStories: FeaturedStoriesModel[] = [];
  filterDropdown: boolean;
  metadatas: MetaModel;
  newsHeadlineLength: number;
  newsGridResponse: HomeModel;
  shortDescriptionLength: number;
  allActive = false;
  imageActive = false;
  videoActive = false;
  docActive = false;
  audioActive = false;
  language: Language;
  mcLanguage = [];
  sLng: number;
  loading = false;
  xDown = null;
  yDown = null;
  featuredEmbeddedCode: SafeHtml;
  buttonsHelper = ButtonsHelper;
  mediaLoaded = false;
  initialLoaded = false;
  latestStoriesLoaded = false;
  storiesCategoryLoaded = false;
  showCategory = true;
  brands: BrandsManageModel[] = [];
  brandsCurrentScroll: number = 0;
  brandsMaxScroll: number = 0;
  innerWidth: number = 0;
  @ViewChild("brandsContainer")
  brandsContainer: ElementRef;

  constructor(
    private mcService: McService,
    private router: Router,
    private ngZone: NgZone,
    private sService: StorageService,
    private meta: Meta,
    private titleService: Title,
    public fontsAndColorsService: FontsAndColorsService,
    private languageService: LanguageService,
    private mcLanguageService: MCLanguageService,
    private fbo: FacebookService,
    private route: ActivatedRoute,
    private googleAnalytics: GoogleAnalyticsEventsService,
    private location: Location,
    private communicationService: CommunicationService,
    private sanitizer: DomSanitizer,
    public webPHelper: WebpHelperService,
    private savedStoryService: SavedStoryService
  ) {
    this.styles = fontsAndColorsService.getPageStyleInfo();
    this.languageService.language.subscribe((language) => {
      this.language = language;
    });
    this.mcLanguageService.language.subscribe((language) => {
      this.getHomeTexts(language);
    });
    this.route.params.subscribe((params: Params) => {
      const id: string = params["id"];
      const title: string = params["title"];
      let media: string = params["media"];

      if (media && media.indexOf("?") > -1)
        media = media.substr(0, media.indexOf("?"));

      if (media) {
        this.openPopup(+media);
      }
    });
    this.route.queryParams.subscribe((params) => {
      if (params["parentBrandUrl"]) {
        this.communicationService.setFromBrands(decodeURI(params["parentBrandUrl"]));
      }
    });
  }

  getHomeTexts(mcLanguage) {
    if (mcLanguage) {
      let homeKeywords = [
        "SearchBoxTitle",
        "SearchBoxText",
        "SearchBoxSubtitle",
      ];
      let homeKeywordIds = [];
      let homeIdToKeyword = [];
      for (const keyword of mcLanguage.keywords) {
        if (homeKeywords.includes(keyword.label)) {
          homeKeywordIds.push(keyword.id);
          homeIdToKeyword[keyword.id] = keyword.label;
        }
      }
      let lngId = localStorage.getItem("lng");
      for (const mccl of mcLanguage.mediaCenterChosenLanguages) {
        if (
          homeKeywordIds.includes(mccl.keywordId) &&
          mccl.languageId == lngId
        ) {
          this.mcLanguage[homeIdToKeyword[mccl.keywordId]] = mccl.translation;
        }
      }
    }
  }

  openPopup(id) {
    this.mediaId = id;
    this.popup = true;
  }

  ngOnInit() {
    this.showCategory = localStorage.getItem("hideCategories") != "1";
    this.language = this.languageService.getLanguage();
    this.getHomeTexts(this.mcLanguageService.getMCLanguage());
    document.addEventListener(
      "touchstart",
      (evt) => {
        this.xDown = evt.touches[0].clientX;
        this.yDown = evt.touches[0].clientY;
      },
      false
    );
    this.sLng = Number(localStorage.getItem("lng"));
    window.scrollTo(0, 0);

    this.getHomepage();
    this.user = TokenManagerService.getUser();
    this.currentUrl = window.location.href;

    this.facebook = [];
    this.getBrands();
  }

  getBrands() {
    this.mcService.getBrands().subscribe(
      (response: any) => {
        this.brands = response.clients;
        setTimeout(() => {
          this.brandsMaxScroll = this.brandsContainer?.nativeElement?.offsetWidth;
        }, 1000);
      },
      (error) => {
        //
      }
    );
  }

  handleTouchStart(evt) {
    this.xDown = evt.touches[0].clientX;
    this.yDown = evt.touches[0].clientY;
  }

  resize() {
    if (this.homeModelResponse.latesStoryGridType === "Columns2") {
      this.newsHeadlineLength = 155;
      if (window.innerWidth <= 1500 && window.innerWidth >= 1200) {
        this.newsHeadlineLength = 120;
      }
      if (window.innerWidth <= 1200 && window.innerWidth >= 700) {
        this.newsHeadlineLength = 110;
      }
    }
    if (this.homeModelResponse.latesStoryGridType === "Columns3") {
      this.newsHeadlineLength = 90;
      this.shortDescriptionLength = 140;
      if (window.innerWidth <= 1500 && window.innerWidth >= 1200) {
        this.newsHeadlineLength = 65;
        this.shortDescriptionLength = 95;
      }
      if (window.innerWidth <= 1200 && window.innerWidth >= 1000) {
        this.newsHeadlineLength = 120;
        this.shortDescriptionLength = 95;
      }
      if (window.innerWidth <= 1000 && window.innerWidth >= 700) {
        this.newsHeadlineLength = 75;
        this.shortDescriptionLength = 75;
      }
    }
    if (this.homeModelResponse.latesStoryGridType === "Columns4") {
      this.newsHeadlineLength = 60;
      if (window.innerWidth <= 1500 && window.innerWidth >= 1200) {
        this.newsHeadlineLength = 15;
      }
      if (window.innerWidth <= 1200 && window.innerWidth >= 1000) {
        this.newsHeadlineLength = 110;
      }
      if (window.innerWidth <= 1000 && window.innerWidth >= 700) {
        this.newsHeadlineLength = 100;
      }
    }
    this.innerWidth = window.innerWidth;
  }

  // getStoryUrl(storyId, stories) {
  //   return (
  //     this.currentUrl.replace("home", "story") +
  //     "/" +
  //     storyId +
  //     "/" +
  //     UrlHelper.getUrlTitle(
  //       stories.find((x) => x.staggingStoryId == storyId).storyTitle
  //     )
  //   );
  // }

  getStoryUrl() {
    return `/story/${this.selectedFs.staggingStoryId}/${UrlHelper.getUrlTitle(
      this.selectedFs.storyTitle
    )}`;
  }

  homepageLoaded() {
    if (this.latestStoriesLoaded && this.initialLoaded) {
      this.resize();
      this.loader = false;
    }
  }

  getHomepage() {
    this.loader = true;
    this.mcService.getHomepageInitial(this.sLng).subscribe(
      (response: any) => {
        this.initialLoaded = true;
        this.homeModelResponse.featuredStories = response.featuredStories;
        this.homeModelResponse.mustRegisterToAccess =
          response.mustRegisterToAccess;
        this.homeModelResponse.mustRegisterToDownload =
          response.mustRegisterToDownload;
        this.homeModelResponse.hideCounters = response.hideCounters;
        this.homeModelResponse.latesStoryGridType = response.latesStoryGridType;
        this.grid = this.homeModelResponse.latesStoryGridType;
        this.homeModelResponse.title = response.title;
        this.homeModelResponse.bodyText = response.bodyText;
        this.homeModelResponse.additionalBodyText = response.additionalBodyText;
        this.homeModelResponse.socialSharing = response.socialSharing;

        this.fStories = response.featuredStories;
        this.selectedFs = this.homeModelResponse.featuredStories[0];
        for (let i = 1; i < this.fStories.length && i < 3; i++) {
          this.nextFStories.push(this.fStories[i]);
        }
        for (const x of this.homeModelResponse.featuredStories) {
          this.images = x.images;
          this.video = x.video;
          this.audio = x.audio;
          this.document = x.document;
        }
        this.backgroundUrl =
          this.homeModelResponse?.featuredStories[0]?.fullHDUrl || "";

        for (const x of this.fStories) {
          if (x.fullHDUrl === "") {
            this.thumbUrl = x.thumbUrl;
          }
        }

        for (const featuredStory of this.homeModelResponse.featuredStories) {
          if (featuredStory.featuredEmbeddedCode) {
            featuredStory.embeddedCode = this.sanitizer.bypassSecurityTrustHtml(
              featuredStory.featuredEmbeddedCode
            );
          }
        }
        if (this.homeModelResponse.socialSharing != null) {
          this.socials = this.homeModelResponse.socialSharing;
        }
        this.homepageLoaded();
      },
      (error) => { }
    );
    this.mcService.getHomepageLatestStories(this.sLng).subscribe(
      (response: any) => {
        this.latestStoriesLoaded = true;
        this.homeModelResponse.latestStories = response.latestStories;
        for (const latestStory of this.homeModelResponse.latestStories) {
          if (latestStory.featuredEmbeddedCode) {
            latestStory.embeddedCode = this.sanitizer.bypassSecurityTrustHtml(
              latestStory.featuredEmbeddedCode
            );
          }
        }
        this.loadStoryGroupings();
        this.homepageLoaded();
      },
      (error) => { }
    );
  }

  loadStoryGroupings() {
    let count = 0;
    for (const story of this.homeModelResponse.latestStories) {
      if (this.storyGroupings[count].length >= 4) count++;
      if (!this.storyGroupings[count]) this.storyGroupings[count] = [];
      this.storyGroupings[count].push(story);
    }
  }

  changeStory(story: FeaturedStoriesModel, i) {
    this.videoUrl = "";
    this.images = story.images;
    this.video = story.video;
    this.audio = story.audio;
    this.document = story.document;
    this.selectedFs = story;
    if (story.mediaType == "Image") {
      this.backgroundUrl = story.fullHDUrl;
      this.featuredEmbeddedCode = null;
    }
    if (story.mediaType == "Video") {
      this.videoUrl = story.fullHDUrl;
      this.featuredEmbeddedCode = null;
    }
    if (story.featuredEmbeddedCode) {
      this.selectedFs.mediaType = null;
      this.backgroundUrl = "";
      this.featuredEmbeddedCode = this.sanitizer.bypassSecurityTrustHtml(
        story.featuredEmbeddedCode
      );
    }
    this.activeSlide = i;
  }

  moveLeftBrands() {
    const brandCard = this.brandsContainer.nativeElement.querySelector(".brand-box");
    const moveAmount = brandCard.offsetWidth + 30;
    this.brandsCurrentScroll = this.brandsCurrentScroll - moveAmount;
    if (this.brandsCurrentScroll < 0) this.brandsCurrentScroll = 0;
  }

  moveRightBrands() {
    const brandCard = this.brandsContainer.nativeElement.querySelector(".brand-box");
    const moveAmount = brandCard.offsetWidth + 30;
    this.brandsCurrentScroll = this.brandsCurrentScroll + moveAmount;
    if (this.brandsCurrentScroll > this.brandsMaxScroll - this.innerWidth)
      this.brandsCurrentScroll = this.brandsMaxScroll - this.innerWidth;
  }

  prevSlide() {
    this.featuredEmbeddedCode = null;
    if (this.activeSlide != 0) {
      this.activeSlide = this.activeSlide - 1;
    } else {
      this.activeSlide = this.fStories.length - 1;
    }
    this.changeSlide();
  }

  nextSlide() {
    this.featuredEmbeddedCode = null;
    if (this.activeSlide != this.fStories.length - 1) {
      this.activeSlide = this.activeSlide + 1;
    } else {
      this.activeSlide = 0;
    }
    this.changeSlide();
  }

  changeSlide() {
    this.selectedFs = this.fStories[this.activeSlide];
    this.backgroundUrl = this.fStories[this.activeSlide].fullHDUrl;
    for (const x of this.fStories) {
      if (x.mediaType == "Video") {
        this.videoUrl = this.fStories[this.activeSlide].fullHDUrl;
      }
    }

    if (this.selectedFs.featuredEmbeddedCode) {
      this.featuredEmbeddedCode = this.sanitizer.bypassSecurityTrustHtml(
        this.selectedFs.featuredEmbeddedCode
      );
      this.selectedFs.mediaType = null;
      this.backgroundUrl = "";
    }
    this.nextFStories = [];
    for (let i = this.activeSlide + 1; this.nextFStories.length < 2; i++) {
      if (i >= this.fStories.length) i = 0;
      this.nextFStories.push(this.fStories[i]);
    }
  }

  readMore(id, title) {
    localStorage.setItem("storyId", JSON.stringify(id));
    if (localStorage.getItem("Token") === null) {
      this.user.id = null;
    }
    if (this.user.cmsUser == null) {
      this.googleAnalytics.emitEvent(
        "story/" + id.staggingStoryId,
        "view",
        this.user.id ? this.user.id.toString() : "anonymous"
      );
    }
    this.googleAnalytics.emitArticleEvent("homepage", id.staggingStoryId);
    this.googleAnalytics.emitReadMoreEvent(
      "slider",
      title,
      "/story/" + id.staggingStoryId + "/" + UrlHelper.getUrlTitle(title)
    );
    this.router.navigate([
      "/story/" + id.staggingStoryId + "/" + UrlHelper.getUrlTitle(title),
    ]);
  }

  brandStories(brandName) {
    this.router.navigateByUrl('/brands/' + brandName);
  }

  goToMc(url, clientId, clientName) {
    this.mcService.redirectToMc(clientId).subscribe(
      (response: any) => {
        const token = response.token;
        if (url){
          window.location.href = url + '&token=' + token;
        } else {
          this.brandStories(clientName)
        }
      },
      (error) => {

        if (url){
          window.location.href = url;
        } else {
          this.brandStories(clientName)

        }
      });
  }

  toggleSavedStory(storyId, event: Event) {
    event.stopPropagation();
    if (this.inSavedStories(storyId))
      this.savedStoryService
        .remove(storyId)
        .then((response: any) => { })
        .catch((error) => {
          console.error(error);
        });
    else
      this.savedStoryService
        .add(storyId)
        .then((response: any) => { })
        .catch((error) => {
          console.error(error);
        });
  }

  inSavedStories(storyId) {
    return this.savedStoryService.inSavedStories(storyId);
  }

  

}
