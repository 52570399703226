
import { catchError, map } from 'rxjs/operators';

import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { LoginModel, NewPasswordModel, UserModel } from '../../login/login-model';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ToastrConfig } from '../helpers/toastr-config';

@Injectable()
export class TokenManagerService {
  public token: string;
  private userLoginURL = environment.apiUrl + '/MediaCenterUser/Login';
  private ForgotPasswordURL = environment.apiUrl + '/MediaCenterUser/ForgotPassword';
  private ResetPasswordURL = environment.apiUrl + '/MediaCenterUser/ResetPassword';
  private resetPasswordAdminURL = environment.apiUrl + '/MediaCenterUser/ResetPasswordAdmin';
  private checkAuthenticationUrl = environment.apiUrl + '/MediaCenterUser/UserAuthentication';
  private checkPasswordCodeUrl = environment.apiUrl + '/MediaCenterUser/CheckPasswordCode';
  private checkPasswordCodeAdminUrl = environment.apiUrl + '/MediaCenterUser/CheckPasswordCodeAdmin';
  private mustRegisterUrl = environment.apiUrl + '/MediaCenterUser/MustRegister';
  private registerImportedURL = environment.apiUrl + '/MediaCenterUser/RegisterImported';

  static getToken() {

    if (localStorage.getItem('Token') === null) {
      const tokenObject = JSON.parse(localStorage.getItem('Token'));
      if (tokenObject !== null) {
        return tokenObject.token;
      }
    } else {
      const tokenObject = JSON.parse(localStorage.getItem('Token'));
      if (tokenObject !== null) {
        return tokenObject.token;
      }
    }

  }

  static getUser(): UserModel {
    if (localStorage.getItem('User') === null) {
      const object = JSON.parse(localStorage.getItem('User'));

      if (!object) {
        const user = new UserModel();
        user.id = 0;

        return user;
      }

      return object.user;

    } else {
      const object = JSON.parse(localStorage.getItem('User'));

      if (!object) {
        const user = new UserModel();
        user.id = 0;

        return user;
      }

      return object.user;
    }
  }

  /*static isAdmin() {
   if (localStorage.getItem('currentUser') === null) {
   return (localStorage.getItem('isAdmin') === 'true');
   } else {
   return (localStorage.getItem('isAdmin') === 'true');
   }
   }*/

  constructor(private http: HttpClient, private toastr: ToastrService) {
    const token = JSON.parse(localStorage.getItem('Token'));
    this.token = token && token.token;
  }

  private handleError(response: any) {
    
    let errMsg: string;
    if (response.status > 200) {
      errMsg = response.error.message;
    } else {
      errMsg = response.message ? response.message : response.toString();
    }

    this.toastr.error(errMsg, 'Error', ToastrConfig.error);

    return observableThrowError({ status: response.status, message: errMsg });
  }

  login(user: LoginModel) {

    return this.http.post<any>(this.userLoginURL, user).pipe(
      map((response) => {
        const token = response && response.token;
        if (token) {
          this.token = token;

          if (response.user.role === 3 || response.user.role === 4 || response.user.role === 5 || response.user.role === 6) {
            return observableThrowError('Wrong email or password!');
          }

          localStorage.setItem('Token', JSON.stringify({ token: token }));
          localStorage.setItem('User', JSON.stringify({ user: response.user }));

          return response;
        } else {
          return false;
        }
      }),
      catchError((response) => this.handleError(response)));
  }

  isAuthenticated() {
    const promise = new Promise(
      (resolve, reject) => {
        resolve(TokenManagerService.getToken());
      }
    );
    return promise;
  }


  logout() {
    this.token = null;

    localStorage.removeItem('Token');
    localStorage.removeItem('User')

    sessionStorage.removeItem('mustRegister');
  }

  ForgotPassword(email: string) {
    return this.http.post(this.ForgotPasswordURL + '?email=' + email, true);
  }

  ResetPassword(model: NewPasswordModel) {
    return this.http.post(this.ResetPasswordURL, model);
  }

  registerImported(model: NewPasswordModel) {
    return this.http.post(this.registerImportedURL, model);
  }

  resetPasswordAdmin(model: NewPasswordModel) {
    return this.http.post(this.resetPasswordAdminURL, model);
  }

  checkAuthentication(resetCode) {
    return this.http.post<any>(this.checkAuthenticationUrl, resetCode).pipe(
      map((response) => {
        const token = response && response.token;
        if (token) {
          this.token = token;
          localStorage.setItem('Token', JSON.stringify({ token: token }));
          localStorage.setItem('User', JSON.stringify({ user: response.user }));
          return response;
        } else {
          return false;
        }
      }),
      catchError((response) => this.handleError(response)));;
  }

  checkPasswordCode(code) {
    return this.http.post(this.checkPasswordCodeUrl + '?code=' + code, true);
  }

  checkPasswordCodeAdmin(code) {
    return this.http.post(this.checkPasswordCodeAdminUrl + '?code=' + code, true);
  }

  mustRegister() {
    return this.http.get(this.mustRegisterUrl);
  }

}
