<div id="toast-container" toastContainer></div>
<app-header-2 *ngIf="styles && layoutResponseModel && !isVodafone && !isEasyJet && !isDna"
  [layoutResponseModel]="layoutResponseModel"></app-header-2>
<app-header-2 *ngIf="styles && layoutResponseModel && isVodafone && !isDna"
  [layoutResponseModel]="layoutResponseModel"></app-header-2>
<app-header-3 *ngIf="styles && layoutResponseModel && isEasyJet"
  [layoutResponseModel]="layoutResponseModel"></app-header-3>
  <app-header-5 *ngIf="styles && layoutResponseModel && isDna"
  [layoutResponseModel]="layoutResponseModel"></app-header-5>
<div *ngIf="styles" class="page-wrapper">
  <router-outlet></router-outlet>
</div>
<app-footer-5 *ngIf="styles && isDna" [layoutResponseModel]="layoutResponseModel" [isDna]="isDna"
[clientName]="clientName"></app-footer-5>
<app-footer *ngIf="styles && !isDna" [layoutResponseModel]="layoutResponseModel" [isEasyJet]="isEasyJet" 
  [clientName]="clientName"></app-footer>
