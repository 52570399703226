import { Component, OnInit, Input } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CustomValidators } from "ng2-validation";
import { ProfileModel } from "../../header/profile/profile-model";
import { Language } from "../../models/language/language.model";
import { DownloadBoxService } from "../../shared/services/download-box.service";
import { LanguageService } from "../../shared/services/language.service";
import { McService } from "../../shared/services/mc-service";
import { StorageService } from "../../shared/services/storage.service";
import { TokenManagerService } from "../../shared/services/token-manager.service";
import * as JSZip from "jszip";
import * as JSZipUtils from "jszip-utils";
import { DownloadAddToModel } from "../media/media-model";
import { GoogleAnalyticsEventsService } from "../../shared/services/google-analytics-events.service";
import { HttpClient } from "@angular/common/http";
import { SavedStoryService } from "../../shared/services/saved-story.service";
import { Router } from "@angular/router";
import { MyFilesService } from "../../shared/services/my-files.service";
import { DeleteAccountService } from "../../shared/services/delete-account.service";
import { MyFilesModel } from "../../shared/services/my-files-model";

@Component({
  selector: "app-file-history",
  templateUrl: "./file-history.component.html",
  styleUrls: ["./file-history.component.less"],
})
export class FileHistoryComponent implements OnInit {
  @Input() isDna: boolean;
  // isDna: boolean;
  loader = 0;
  language: Language;
  filters: any;
  fileType = "all";
  fileHistoryModel: any = [];
  filesFromCart: any = [];
  editFormOpened: boolean = true;
  deleteAccount: boolean = false;
  addDropdown: boolean;
  isPasswordSet: boolean;
  passwordForDeleting: string;
  dropdownStyle: boolean;
  dropdownLabel: string;
  dropdownLabel2: string;
  dropdownLabel3: string;
  list: string[];
  list2: string[];
  list3: string[];
  patternPassword = /^\S*$/;
  isAdmin = false;
  confirmError = false;
  facebook = false;
  twitter = false;
  instagram = false;
  linkedin = false;
  youtube = false;
  wordpress = false;
  dropdown = false;
  languageDropDown: boolean;
  categoryDropdown: boolean;
  valForm: FormGroup;
  categories: any[] = [];
  languages: any[] = [];
  updateMessage: string;
  oldPassword: boolean;
  showPw: boolean;
  enterPw: boolean;
  mediaUser: boolean;
  users: any[] = [];
  allUsers: string[] = [];
  userType: string[] = [];
  usersToShow: any[] = [];
  cartMedias: any[];
  cartFilesOpened: boolean = true;
  noMediaUserType: boolean;
  userFilled: boolean;
  userFilledValid: boolean;
  registerResponseModel = new ProfileModel();
  dateFrom: string | number | Date;
  dateTo: string | number | Date;
  dateToButton: boolean;
  dateFromButton: boolean;
  user: any = {};
  cart: MyFilesModel = new MyFilesModel([], []);
  activeSection = "my-profile";
  savedStories: any = {};
  languageId: number;
  cartFiles: {};

  filtersMenu = false;
  bkg = false;
  bkgFilters = false;
  constructor(
    private languageService: LanguageService,
    private mcService: McService,
    private fb: FormBuilder,
    private sService: StorageService,
    private googleAnalytics: GoogleAnalyticsEventsService,
    private http: HttpClient,
    private downloadBoxService: DownloadBoxService,
    private savedStoryService: SavedStoryService,
    private router: Router,
    private myFilesService: MyFilesService,
    private deleteAccountService: DeleteAccountService,
    private authService: TokenManagerService
  ) {
    this.language = languageService.getLanguage();
  }

  ngOnInit() {
    this.languageId = Number(localStorage.getItem("lng"));
    this.isPasswordSet = TokenManagerService.getUser().isPasswordSet;
    this.myFilesService.files.subscribe((files) => {
      this.updateCartFiles(files);
    });
    // this.sService.getPw().subscribe(term => {
    //   this.isPasswordSet = term;
    // });
    this.cart = this.myFilesService.getMyFiles();
    this.updateCartFiles(this.cart);
    this.getDownloadBoxes();
    this.getSavedStories();
    this.getAccount();
    this.valForm = this.fb.group({
      firstname: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(50)]),
      ],
      lastname: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(50)]),
      ],
      telephone: [null],
      newsletter: [null],
      confirmPassword: [null],
      oldPassword: [null],
      facebook: [null],
      twitter: [null],
      instagram: [null],
      linkedin: [null],
      youtube: [null],
      wordpress: [null],
      password: [
        null,
        Validators.compose([
          Validators.maxLength(50),
          Validators.minLength(4),
          Validators.pattern(this.patternPassword),
        ]),
      ],
      email: [
        null,
        Validators.compose([Validators.required, CustomValidators.email]),
      ],
      company: [null],
    });

    this.addDropdown = false;
    this.dropdownLabel = "Select media category";
    this.dropdownLabel2 = "Select categories";
    this.dropdownLabel3 = "Select your languages";
    this.list = ["dummy", "dumyy"];
    this.list2 = ["dummy", "dumyy"];
    this.list3 = ["dummy", "dumyy"];
    this.filters = [
      {
        type: "all",
        text: this.language.allFiles,
        faClass: "fal fa-archive",
      },
      {
        type: "image",
        text: this.language.imageFiles,
        faClass: "fal fa-camera-retro",
      },
      {
        type: "video",
        text: this.language.videoFiles,
        faClass: "fal fa-video",
      },
      {
        type: "document",
        text: this.language.documents,
        faClass: "fal fa-file",
      },
      {
        type: "audio",
        text: this.language.audioFiles,
        faClass: "fal fa-volume-up",
      },
    ];
  }

  filterType(type) {
    this.fileType = type;
  }

  openFilters(){
    this.filtersMenu = true;
    this.bkgFilters = true;
  }
  closeFilters(){
    this.filtersMenu = false;
    this.bkgFilters = false;
  }

  getSelectedCategoriesText(): string {
    if (
      this.categories == null ||
      this.categories == undefined ||
      this.categories.length == 0
    ) {
      return "";
    }
    let text = "";
    for (let i = 0; i < this.categories.length - 1; i++) {
      text += this.categories[i] + ", ";
    }
    text += this.categories[this.categories.length - 1];
    if (text.length > 30) {
      text = text.substring(0, 30) + "...";
    }
    return text;
  }

  getAccount() {
    this.loader++;
    this.mcService.getUpdate().subscribe(
      (response: any) => {
        this.registerResponseModel = response;
        this.isAdmin = !!this.registerResponseModel.adminModel?.id;

        if (!this.isAdmin) {
          this.userType =
            this.registerResponseModel.mediaUserModel.mediaUserTypes;
          for (const x of this.registerResponseModel.userGroups) {
            for (const y of this.userType) {
              if (x.id === y) {
                x.checked = true;
                this.usersToShow.push(x.id);
                this.users.push(x.id);
              }
            }
          }
          this.allUsers = this.users;
          if (
            this.registerResponseModel.mediaUserModel.facebookLink != "" &&
            this.registerResponseModel.mediaUserModel.facebookLink !== undefined
          ) {
            this.facebook = true;
          }
          if (
            this.registerResponseModel.mediaUserModel.twitterLink != "" &&
            this.registerResponseModel.mediaUserModel.twitterLink !== undefined
          ) {
            this.twitter = true;
          }
          if (
            this.registerResponseModel.mediaUserModel.instagramLink != "" &&
            this.registerResponseModel.mediaUserModel.instagramLink !==
            undefined
          ) {
            this.instagram = true;
          }
          if (
            this.registerResponseModel.mediaUserModel.youTubeLink != "" &&
            this.registerResponseModel.mediaUserModel.youTubeLink !== undefined
          ) {
            this.youtube = true;
          }
          if (
            this.registerResponseModel.mediaUserModel.linkedInLink != "" &&
            this.registerResponseModel.mediaUserModel.linkedInLink !== undefined
          ) {
            this.linkedin = true;
          }
          if (
            this.registerResponseModel.mediaUserModel.wordPressLink != "" &&
            this.registerResponseModel.mediaUserModel.wordPressLink !==
            undefined
          ) {
            this.wordpress = true;
          }
          for (const x of this.registerResponseModel.mediaUserModel
            .languageIds) {
            for (const y of this.registerResponseModel.languages) {
              if (x === y.id) {
                y.checked = true;
                this.languages.push(y.label);
              }
            }
          }
          for (const x of this.registerResponseModel.mediaUserModel
            .customCategoryIds) {
            for (const y of this.registerResponseModel.customCatogories) {
              if (x === y.id) {
                y.marked = true;
                this.categories.push(y.label);
              }
            }
          }
        } else {
          this.registerResponseModel.adminModel.telephoneNumber =
            this.registerResponseModel.adminModel.telephone;
        }
      },
      (error) => { },
      () => {
        this.loader--;
      }
    );
  }

  addLanguage(lang) {
    const index = this.registerResponseModel.mediaUserModel.languageIds.indexOf(
      lang.id
    );
    lang.checked = !lang.checked;
    if (lang.checked) {
      this.registerResponseModel.mediaUserModel.languageIds.push(lang.id);
      this.languages.push(lang.label);
    } else {
      this.registerResponseModel.mediaUserModel.languageIds.splice(index, 1);
      this.languages.splice(index, 1);
    }
  }

  addCategory(category, event) {
    const index =
      this.registerResponseModel.mediaUserModel.customCategoryIds.indexOf(
        category.id
      );
    category.marked = !category.marked;
    if (category.marked) {
      this.registerResponseModel.mediaUserModel.customCategoryIds.push(
        category.id
      );
      this.categories.push(category.label);
    } else {
      this.registerResponseModel.mediaUserModel.customCategoryIds.splice(
        index,
        1
      );
      this.categories.splice(index, 1);
    }
  }

  

  addUser(user, event) {
    event.stopPropagation();
    const index = this.users.indexOf(user.id);
    user.checked = !user.checked;

    if (user.checked) {
      this.users.push(user.id);
      this.usersToShow.push(user.id);
      this.noMediaUserType = true;
      this.userFilled = true;
    } else {
      this.users.splice(index, 1);
      this.usersToShow.splice(index, 1);
    }

    if (this.users.length == 0) this.noMediaUserType = false;
    this.registerResponseModel.mediaUserModel.mediaUserTypes = this.users;
  }

  checkIfEmpty() {
    if (this.valForm.controls["email"].value.length === 0)
      this.valForm.controls["email"].setValidators(Validators.required);
    this.valForm.controls["email"].setValidators(CustomValidators.email);
  }

  openAddDropdown() {
    this.addDropdown = !this.addDropdown;
  }

  checkPassword() {
    if (
      this.valForm.controls["password"].value ===
      this.valForm.controls["confirmPassword"].value
    )
      this.confirmError = false;
  }

  ifEmpty() {
    if (this.valForm.controls["oldPassword"].value != "") {
      this.showPw = true;
      this.enterPw = false;
    }
    if (
      this.valForm.controls["password"].value === "" &&
      this.valForm.controls["oldPassword"].value != ""
    ) {
      this.oldPassword = true;
    }

    if (
      this.valForm.controls["oldPassword"].value == "" ||
      this.valForm.controls["oldPassword"].value == null
    ) {
      this.enterPw = false;
    }

    if (
      this.valForm.controls["oldPassword"].value == "" ||
      this.valForm.controls["oldPassword"].value == null
    ) {
      this.oldPassword = false;
    }
  }

  setConfirmError() {
    if (
      this.valForm.controls["password"].value !==
      this.valForm.controls["confirmPassword"].value
    ) {
      this.confirmError = true;
    }
    if (
      this.valForm.controls["password"].value !== "" &&
      this.valForm.controls["oldPassword"].value != ""
    ) {
      this.oldPassword = false;
    }
    if (
      this.valForm.controls["password"].value == "" &&
      this.valForm.controls["oldPassword"].value != ""
    ) {
      this.oldPassword = true;
    }
    if (
      this.valForm.controls["password"].value !== "" &&
      this.valForm.controls["oldPassword"].value == ""
    ) {
      this.enterPw = true;
    }

    // if (this.valForm.controls['password'].value === '') {
    //   this.confirmError = false;
    // }
  }

  updateAccount() {
    this.loader++;
    this.mcService
      .update(
        this.isAdmin
          ? this.registerResponseModel.adminModel
          : this.registerResponseModel.mediaUserModel
      )
      .subscribe(
        (response) => {
          if (this.isAdmin) {
            const { firstName, lastName } =
              this.registerResponseModel.adminModel;
            const fullName = `${firstName} ${lastName}`;
            this.sService.setName(fullName);
            this.registerResponseModel.adminModel.fullName = fullName;
            localStorage.setItem(
              "User",
              JSON.stringify({ user: this.registerResponseModel.adminModel })
            );
          } else {
            const { firstName, lastName } =
              this.registerResponseModel.mediaUserModel;
            const fullName = `${firstName} ${lastName}`;
            this.sService.setName(fullName);
            this.registerResponseModel.mediaUserModel.fullName = fullName;
            localStorage.setItem(
              "User",
              JSON.stringify({
                user: this.registerResponseModel.mediaUserModel,
              })
            );
          }
        },
        (error) => { },
        () => this.loader--
      );
  }

  newsletterCheck() {
    this.registerResponseModel.mediaUserModel.receiveNewsletter =
      !this.registerResponseModel.mediaUserModel.receiveNewsletter;
  }

  toggleDropdown() {
    this.dropdown = !this.dropdown;
  }

  onAccountFormSubmit(e) {
    e.stopPropagation();
    if (
      this.valForm.controls["oldPassword"].value == "" &&
      this.valForm.controls["password"].value != ""
    ) {
      this.enterPw = true;
    }
    if (
      !this.valForm.controls["oldPassword"].value &&
      !this.valForm.controls["password"].value
    ) {
      this.enterPw = false;
      this.oldPassword = false;
    }
    for (const c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }

    if (
      !this.isAdmin &&
      this.registerResponseModel.mediaUserModel.mediaUserTypes.length === 0
    ) {
      this.userFilled = false;
    } else {
      this.userFilled = true;
    }

    if (this.isAdmin) {
      this.registerResponseModel.adminModel.isPasswordSet = this.isPasswordSet;
    } else {
      this.registerResponseModel.mediaUserModel.isPasswordSet =
        this.isPasswordSet;
    }

    if (
      this.valForm.valid &&
      !this.oldPassword &&
      !this.enterPw &&
      this.userFilled
    ) {
      this.updateAccount();
    }
  }

  openDateFrom() {
    this.dateFromButton = !this.dateFromButton;
  }

  openDateTo() {
    this.dateToButton = !this.dateToButton;
  }

  changeFromDate(event) {
    if (event.value !== null) {
      if (new Date(this.dateFrom).getTime() > new Date(this.dateTo).getTime()) {
        this.dateTo = new Date();
      }
    }
  }

  changeToDate(event) {
    if (event.value !== null) {
      if (new Date(this.dateFrom).getTime() > new Date(this.dateTo).getTime()) {
        const dateTo: Date = new Date(this.dateTo);
        this.dateFrom = new Date(dateTo.getTime());
      }
    }
  }

  showFile(storyMedia) {
    if (
      this.fileType != "all" &&
      storyMedia.mediaType.toLowerCase() != this.fileType
    )
      return false;
    return true;
  }

  getDownloadBoxes() {
    this.loader++;
    this.downloadBoxService.getAll().subscribe(
      (response: any) => {
        this.fileHistoryModel = response;
        if (
          this.myFilesService.getMyFiles()?.length == 0 &&
          this.fileHistoryModel.downloadBoxes.length > 0
        )
          this.fileHistoryModel.downloadBoxes[0].opened = true;
      },
      (error) => { },
      () => {
        this.loader--;
      }
    );
  }

  updateCartFiles(files) {
    this.loader++;
    this.mcService.downloadBox(files).subscribe((response) => {
      this.filesFromCart = response;
      this.cartMedias = [];
      this.filesFromCart.downloadBoxList.forEach((element) => {
        element.media.forEach((m) => {
          m.media.downloadLink = m.downloadLinks[0].downloadUrl;
          this.cartMedias.push(m.media);
        });
      });
      this.loader--;
    });
  }

  parseDate(date) {
    if (!(date instanceof Date)) date = new Date(date);
    let options: any = { year: "numeric", month: "short", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  }

  showBox(box) {
    let date = box.date;
    if (!(date instanceof Date)) date = new Date(date);
    const inDateRange =
      (!this.dateFrom || this.dateFrom < date) &&
      (!this.dateTo || this.dateTo > date);
    if (!inDateRange) return false;

    return box.storyMedias?.some((media) => this.showFile(media)) || false;
  }

  showCartBox(medias: any[]) {
    return medias?.some((media) => this.showFile(media)) || false;
  }

  downloadBox(box, isCart = false) {
    const component = this;
    const urls = isCart
      ? box.map((media) => media.downloadLink)
      : box.storyMedias.map((media) => media.downloadLink);
    const publishedMediaIds = isCart
      ? box.filter((media) => media.isPublished).map((media) => media.id)
      : box.storyMedias.filter((media) => media.isPublished).map((media) => media.id);
    const storyMediaIds = isCart
      ? box.filter((media) => !media.isPublished).map((media) => media.id)
      : box.storyMedias.filter((media) => !media.isPublished).map((media) => media.id);
    const zip = new JSZip();
    let count = 0;
    const zipFilename = "download.zip";

    if (urls.length > 1) {
      urls.forEach((url) => {
        const filename = url.split("/")[8];

        // loading a file and add it in a zip file
        JSZipUtils.getBinaryContent(url, function (err, data) {
          zip.file(filename, data, { binary: true });
          count++;

          if (count === urls.length) {
            zip.generateAsync({ type: "blob" }).then(function (content) {
              saveAs(content, zipFilename);

              if (!component.user.cmsUser) {
                component.addDownload(storyMediaIds, publishedMediaIds);
              }

              if (isCart) {
                component.downloadBoxService.createDownloadBox().subscribe((response) => {
                  component.myFilesService.clearFiles();
                }, (error) => console.error(error));
              }

            });
          }
        });
      });
    } else {
      for (const x of urls) {
        this.http.get(x, { responseType: "blob" }).subscribe(
          (blob: Blob) => {
            const filename = x.split("/")[8];

            saveAs(blob, filename);

            if (!component.user.cmsUser) {
              component.addDownload(storyMediaIds, publishedMediaIds);
            }


            if (isCart) {
              component.downloadBoxService.createDownloadBox().subscribe((response) => {
                component.myFilesService.clearFiles();
              }, (error) => console.error(error));
            }

          },
          (error) => console.error(error)
        );
      }
    }
  }

  downloadCart() {
    const files = this.myFilesService.getMyFiles();
    this.mcService.downloadBox(files).subscribe((response) => {
      this.filesFromCart = response;
      this.cartMedias = [];
      this.filesFromCart.downloadBoxList.forEach((element) => {
        element.media.forEach((m) => {
          m.media.downloadLink = m.downloadLinks[0].downloadUrl;
          this.cartMedias.push(m.media);
        });
      });
      this.downloadBox(this.cartMedias, true);
    });
  }

  addDownload(storyMediaIds, publishedMediaIds) {
    this.user = TokenManagerService.getUser();
    if (localStorage.getItem("Token") === null) {
      this.user.id = null;
    }
    const niz = new DownloadAddToModel();
    niz.publishedMediaIds = publishedMediaIds;
    niz.storyMediaIds = storyMediaIds;
    niz.userId = this.user.id;
    niz.publishedMediaIds.forEach((mediaId) => {
      this.googleAnalytics.emitEvent(
        "media/" + mediaId,
        "download-pm",
        niz.userId.toString()
      );
    });
    niz.storyMediaIds.forEach((mediaId) => {
      this.googleAnalytics.emitEvent(
        "media/" + mediaId,
        "download",
        niz.userId.toString()
      );
    });
    this.mcService.addDownloadToMedia(niz).subscribe(
      (response) => { },
      (error) => { }
    );
  }

  getSavedStories() {
    this.savedStoryService.getAll(this.languageId).subscribe(
      (response: any) => {
        this.savedStories = response;
      },
      (error) => { }
    );
  }

  showNoResults() {
    if (this.activeSection == "file-history")
      return !this.fileHistoryModel?.downloadBoxes?.some((box) =>
        this.showBox(box)
      );
    else return this.savedStories?.stories?.length == 0;
  }

  wrongPassword: boolean = false;

  deleteMyAccount() {
    this.deleteAccountService.deleteAccount(this.passwordForDeleting).subscribe(
      (response) => {
        this.authService.logout();
        this.router.navigate(["/"]);
      },
      (error) => {
        this.wrongPassword = true;
        console.error(error);
      }
    );
  }

  dontDeleteMyAccount() {
    this.deleteAccount = false;
    this.wrongPassword = false;
    this.passwordForDeleting = "";
  }
}
