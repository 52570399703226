import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  Output,
  HostListener,
  ElementRef,
  AfterViewInit,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { McService } from "../../shared/services/mc-service";
import {
  AlgoliaNewsRecords,
  AlgoliaMediaRecords,
  ClientLookupModel,
  SearchModel,
  SearchResultModel,
  WebSearchModel,
} from "./search-model";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { StorageService } from "../../shared/services/storage.service";
import { PageStyle } from "../../models/style/style.model";
import { FontsAndColorsService } from "../../shared/services/fonts-and-colors.service";
import { LayoutModel } from "../../layout/layout-model";
import { LanguageService } from "../../shared/services/language.service";
import { Language } from "../../models/language/language.model";
import { DomSanitizer } from "@angular/platform-browser";
import { GoogleAnalyticsEventsService } from "../../shared/services/google-analytics-events.service";
import { timeStamp } from "console";
import { AlgoliaService } from "../../shared/services/algolia.service";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.less"],
})
export class SearchComponent implements OnInit {
  @Input() layoutResponseModel: LayoutModel;
  @Input() search: boolean;
  @Input() template: number;
  @Output() searchChange = new EventEmitter<boolean>();
  @Output() openDateFromEmitter = new EventEmitter<boolean>();
  storiesAndMedia: boolean;
  stories: boolean;
  mediaId: number;
  popup: boolean;
  media: boolean;
  loader: boolean;
  language: Language;
  showingResults: boolean;

  dateFrom: Date = null;
  dateTo: Date = null;

  dateFromButton: boolean;
  dateToButton: boolean;

  result: any;
  styles = new PageStyle();
  headerFooter: any;
  searchModelData = new SearchModel();
  searchResultStories = new SearchResultModel();
  searchResultMedia = new SearchResultModel();
  checked = false;
  checkedb = false;
  searchFrom = "stories";

  constructor(
    private algoliaService: AlgoliaService,
    private mcService: McService,
    private languageService: LanguageService,
    private router: Router,
    private storageService: StorageService,
    private fontsAndColorsService: FontsAndColorsService,
    private elRef: ElementRef,
    private sanitizer: DomSanitizer,
    private googleAnalytics: GoogleAnalyticsEventsService
  ) {
    this.styles = fontsAndColorsService.getPageStyleInfo();
    this.language = languageService.getLanguage();

    this.languageService.language.subscribe((language) => {
      this.language = language;
    });

    router.events.subscribe((val: any) => {
      if (val?.urlAfterRedirects) {
        this.closeSearch();
      }
    });
  }

  isPublished: boolean;
  receiveDataFromChild(event) {
    this.popup = true;
    this.mediaId = event.mediaId;
    this.isPublished = event.isPublished;
  }

  openPopup(id: number) {
    this.popup = true;
    this.mediaId = id;
  }

  @HostListener("document:keyup", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.search) {
      if (event.keyCode === 27) {
        this.closeSearch();
      }
    }
  }

  ngOnInit() {
    this.headerFooter = JSON.parse(localStorage.getItem("headerfooter"));
    this.getSearch();

    this.mcService.searchMcStories(this.searchModelData.searchModel).subscribe(
      (response: any) => {
        this.searchResultStories = response;
      },
      (error) => {
        this.searchModelData.searchModel.stories = false;
      }
    );
    this.mcService.searchMcMedia(this.searchModelData.searchModel).subscribe(
      (response: any) => {
        this.searchResultMedia = response;
      },
      (error) => {
        this.searchModelData.searchModel.media = false;
      }
    );
  }

  getSearch() {
    this.mcService.getSearch().subscribe(
      (response: any) => {
        this.searchModelData = response;
        this.searchModelData.searchModel = new WebSearchModel();
        this.searchModelData.searchModel.pageSize = 12;
        if (localStorage.getItem("searchModel") !== null) {
          this.searchModelData.searchModel = JSON.parse(
            localStorage.getItem("searchModel")
          );
          if (this.searchModelData.subClients == null)
            this.searchModelData.subClients = [];
          if (this.searchModelData.searchModel.stories)
            this.searchFrom = "stories";
          if (this.searchModelData.searchModel.media) this.searchFrom = "media";
          this.searchModelData.subClients.forEach(
            (x) =>
            (x.checked =
              this.searchModelData.searchModel.subClientIds.indexOf(x.id) >
              -1)
          );
        } else {
          this.setSearchFrom(this.searchFrom);
        }
        this.loader = false;
      },
      (error) => {
        this.loader = false;
      }
    );
  }

  wipeSearch() {
    this.searchModelData.searchModel.searchText = "";
    this.setSearchString();
  }

  setSearchString() {
    this.clearResults();
    let searchString = this.searchModelData.searchModel.searchText;
    if (searchString.length > 2) {
      this.showingResults = true;
      this.loader = true;
      const delayDebounceFn = setTimeout(() => {
        this.searchMc();
      }, 250);
      return () => clearTimeout(delayDebounceFn);
    } else {
      this.showingResults = false;
    }
  }

  handleClick(e) {
    if (
      e.target.classList.contains("search-box") ||
      e.target.classList.contains("all-results")
    ) {
      this.closeSearch();
    }
  }

  showStories() {
    if (!this.searchModelData.searchModel.stories || true) {
      this.searchModelData.searchModel.stories = true;
      this.searchModelData.searchModel.media = false;
      this.storageService.setSearchModel(this.searchModelData.searchModel);
      if (this.searchResultStories.stories?.length == 0) this.searchMc();
    }
  }

  showMedia() {
    if (!this.searchModelData.searchModel.media || true) {
      this.searchModelData.searchModel.stories = false;
      this.searchModelData.searchModel.media = true;
      this.storageService.setSearchModel(this.searchModelData.searchModel);
      if (this.searchResultMedia.media?.length == 0) this.searchMc();
    }
  }

  async searchMc() {
    this.loader = true;
    localStorage.setItem(
      "searchModel",
      JSON.stringify(this.searchModelData.searchModel)
    );
    this.searchModelData.searchModel.page = 1;
    if (this.searchModelData.searchModel.stories) {
      this.searchModelData.searchModel.languageId = Number(
        localStorage.getItem("lng")
      );
      const response = await this.algoliaService.searchStories(
        this.searchModelData.searchModel.searchText,
        this.searchModelData.searchModel.page
      );
      const hits: AlgoliaNewsRecords[] = response.hits;
      this.searchResultStories.stories = hits
        .map((hit) => AlgoliaNewsRecords.convertToWebNewsModel(hit))
        .filter((story) => story?.fullHDUrl || story?.thumbUrl);
      this.searchResultStories.maxPages = response.nbPages;

      for (const result of this.searchResultStories.stories) {
        if (result.featuredEmbeddedCode) {
          result.embeddedCode = this.sanitizer.bypassSecurityTrustHtml(
            result.featuredEmbeddedCode
          );
        }
      }
      this.checkMustBeRegistered(
        this.searchResultStories?.mustRegisterToAccess
      );
      this.storageService.setSearchModel(this.searchModelData.searchModel);
    }

    if (this.searchModelData.searchModel.media) {
      const response = await this.algoliaService.searchMedia(
        this.searchModelData.searchModel.searchText,
        this.searchModelData.searchModel.page
      );
      const hits: AlgoliaMediaRecords[] = response.hits;
      this.searchResultMedia.media = hits.map((hit) =>
        AlgoliaMediaRecords.convertToWebManageMediaModel(hit)
      );
      this.searchResultMedia.maxPages = response.nbPages;
      this.checkMustBeRegistered(this.searchResultMedia?.mustRegisterToAccess);
      this.storageService.setSearchModel(this.searchModelData.searchModel);
    }
  }

  checkMustBeRegistered(mustRegisterToAccess) {
    if (
      mustRegisterToAccess === true &&
      localStorage.getItem("Token") == null
    ) {
      this.closeSearch();
      this.loader = false;
      sessionStorage.setItem("redirectUrl", "/" + window.location.pathname);
      this.router.navigateByUrl("/sign-in");
    } else {
      if (this.searchModelData.searchModel.media)
        this.storageService.setSearchItemMedia(this.searchResultMedia);
      else this.storageService.setSearchItemStories(this.searchResultStories);
      this.loader = false;
    }
  }

  closeSearch() {
    this.searchChange.emit(false);
    localStorage.setItem(
      "searchModel",
      JSON.stringify(this.searchModelData.searchModel)
    );
    const body = document.getElementsByTagName("body")[0];
    body.style.position = "inherit";
  }

  clearResults() {
    this.searchResultStories.stories = [];
    this.searchResultStories.maxPages = 0;
    this.storageService.setSearchItemStories(this.searchResultStories);
    this.searchResultMedia.media = [];
    this.searchResultMedia.maxPages = 0;
    this.storageService.setSearchItemMedia(this.searchResultMedia);
  }

  setSearchFrom(tab) {
    this.searchFrom = tab;
    this.searchModelData.searchModel.media = tab != "stories";
    this.searchModelData.searchModel.stories = tab != "media";
    if (this.searchModelData.searchModel.media) {
      this.searchModelData.searchModel.images = true;
      this.searchModelData.searchModel.video = true;
      this.searchModelData.searchModel.document = true;
      this.searchModelData.searchModel.audio = true;
    } else {
      this.searchModelData.searchModel.images = false;
      this.searchModelData.searchModel.video = false;
      this.searchModelData.searchModel.document = false;
      this.searchModelData.searchModel.audio = false;
    }
  }

  clearAll() {
    this.clearResults();
    this.setSearchFrom("stories");
    localStorage.removeItem("searchModel");
  }
}
