import {Component, Input, OnInit} from '@angular/core';
import {CustomValidators} from 'ng2-validation';
import {McService} from '../../shared/services/mc-service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TokenManagerService} from '../../shared/services/token-manager.service';
import {ProfileModel, UserManageModel} from './profile-model';
import {StorageService} from '../../shared/services/storage.service';
import {FontsAndColorsService} from '../../shared/services/fonts-and-colors.service';
import {PageStyle} from '../../models/style/style.model';
import {Language} from "../../models/language/language.model";
import {LanguageService} from "../../shared/services/language.service";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.less']
})
export class ProfileComponent implements OnInit {

  addDropdown: boolean;
  isPasswordSet: boolean;

  @Input() dropdownStyle: boolean;
  dropdownLabel: string;
  dropdownLabel2: string;
  dropdownLabel3: string;
  list: string[];
  list2: string[];
  list3: string[];
  patternPassword = /^\S*$/;
  confirmError = false;
  facebook = false;
  twitter = false;
  instagram = false;
  linkedin = false;
  youtube = false;
  loader: boolean;
  wordpress = false;
  dropdown = false;
  languageDropDown: boolean;
  categoryDropdown: boolean;
  valForm: FormGroup;
  userId: number;
  categories: any[] = [];
  languages: any[] = [];
  updateMessage: string;
  oldPassword: boolean;
  showPw: boolean;
  enterPw: boolean;
  mediaUser: boolean;
  users: any[] = [];
  allUsers: string[] = [];
  userType: string[] = [];
  usersToShow: any[] = [];
  noMediaUserType: boolean;
  styles = new PageStyle();
  language: Language;
  userFilled: boolean;
  userFilledValid: boolean;

  registerResponseModel = new ProfileModel();

  constructor(private mcService: McService, private fb: FormBuilder,private languageService: LanguageService, private sService: StorageService, public fontsAndColorsService: FontsAndColorsService) {
    this.styles = fontsAndColorsService.getPageStyleInfo();
    this.language = languageService.getLanguage();

    this.languageService.language.subscribe(
      (language) => {
        this.language = language;
      }
    );

  }

  ngOnInit() {
    this.userId = TokenManagerService.getUser().id;
    this.isPasswordSet = TokenManagerService.getUser().isPasswordSet;
    // this.sService.getPw().subscribe(term => {
    //   this.isPasswordSet = term;
    // });
    this.getUpdate();
    this.valForm = this.fb.group({
      'firstname': [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
      'lastname': [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
      'telephone': [null],
      'newsletter': [null],
      'confirmPassword': [null],
      'oldPassword': [null],
      'facebook': [null],
      'twitter': [null],
      'instagram': [null],
      'linkedin': [null],
      'youtube': [null],
      'wordpress': [null],
      'password': [null, Validators.compose([Validators.maxLength(50), Validators.minLength(4), Validators.pattern(this.patternPassword)])],
      'email': [null, Validators.compose([Validators.required, CustomValidators.email])],
      'company': [null]
    });

    // this.valForm.controls['firstname'].setValidators(Validators.required);
    // this.valForm.controls['lastname'].setValidators(Validators.required);
    // this.valForm.controls['email'].setValidators(Validators.required);
    // this.valForm.controls['password'].setValidators(Validators.compose([Validators.maxLength(50), Validators.minLength(4), Validators.pattern(this.patternPassword)]));
    // this.valForm.updateValueAndValidity();


    this.addDropdown = false;
    this.dropdownLabel = 'Select media category';
    this.dropdownLabel2 = 'Select categories';
    this.dropdownLabel3 = 'Select your languages';
    this.list = ['dummy', 'dumyy'];
    this.list2 = ['dummy', 'dumyy'];
    this.list3 = ['dummy', 'dumyy'];
  }

  getUpdate() {
    this.loader = true;
    this.mcService.getUpdate(this.userId)
      .subscribe((response: any) => {
          this.registerResponseModel = response;
          this.loader = false;
          this.userType = this.registerResponseModel.mediaUserModel.mediaUserTypes;

          for (const x of this.registerResponseModel.userGroups) {
            for (const y of this.userType) {
              if (x.id === y) {
                x.checked = true;
                this.usersToShow.push(x.id);
                this.users.push(x.id);
              }
            }
          }
          this.allUsers = this.users;
          if (this.registerResponseModel.mediaUserModel.facebookLink != '' && this.registerResponseModel.mediaUserModel.facebookLink !== undefined) {
            this.facebook = true;
          }
          if (this.registerResponseModel.mediaUserModel.twitterLink != '' && this.registerResponseModel.mediaUserModel.twitterLink !== undefined) {
            this.twitter = true;
          }
          if (this.registerResponseModel.mediaUserModel.instagramLink != '' && this.registerResponseModel.mediaUserModel.instagramLink !== undefined) {
            this.instagram = true;
          }
          if (this.registerResponseModel.mediaUserModel.youTubeLink != '' && this.registerResponseModel.mediaUserModel.youTubeLink !== undefined) {
            this.youtube = true;
          }
          if (this.registerResponseModel.mediaUserModel.linkedInLink != '' && this.registerResponseModel.mediaUserModel.linkedInLink !== undefined) {
            this.linkedin = true;
          }
          if (this.registerResponseModel.mediaUserModel.wordPressLink != '' && this.registerResponseModel.mediaUserModel.wordPressLink !== undefined) {
            this.wordpress = true;
          }
          for (const x of this.registerResponseModel.mediaUserModel.languageIds) {
            for (const y of this.registerResponseModel.languages) {
              if (x === y.id) {
                y.checked = true;
                this.languages.push(y.label);
              }
            }
          }
          for (const x of this.registerResponseModel.mediaUserModel.customCategoryIds) {
            for (const y of this.registerResponseModel.customCatogories) {
              if (x === y.id) {
                y.marked = true;
                this.categories.push(y.label);
              }
            }

          }

        },
        (error) => {
          //
        });
  }

  addLanguage(lang) {
    const index = this.registerResponseModel.mediaUserModel.languageIds.indexOf(lang.id);
    lang.checked = !lang.checked;
    if (lang.checked) {
      this.registerResponseModel.mediaUserModel.languageIds.push(lang.id);
      this.languages.push(lang.label);
    }
    else {
      this.registerResponseModel.mediaUserModel.languageIds.splice(index, 1);
      this.languages.splice(index, 1);
    }
  }

  addCategory(category, event) {
    const index = this.registerResponseModel.mediaUserModel.customCategoryIds.indexOf(category.id);
    category.marked = !category.marked;
    if (category.marked) {
      this.registerResponseModel.mediaUserModel.customCategoryIds.push(category.id);
      this.categories.push(category.label);
    }
    else {
      this.registerResponseModel.mediaUserModel.customCategoryIds.splice(index, 1);
      this.categories.splice(index, 1);
    }
    if (event) {
      event.target.style.backgroundColor = event.target.checked ? this.styles.style.mainIdentifyingColor : '';
    }
  }


  addUser(user, event) {
    event.stopPropagation();
    const index = this.users.indexOf(user.id);
    user.checked = !user.checked;


    if (user.checked) {
      this.users.push(user.id);
      this.usersToShow.push(user.id);
      this.noMediaUserType = true;
      this.userFilled = true;
    }
    else {
      this.users.splice(index, 1);
      this.usersToShow.splice(index, 1);
    }
    if (this.users.length == 0) {
      this.noMediaUserType = false;
    }
    this.registerResponseModel.mediaUserModel.mediaUserTypes = this.users;
    event.target.style.backgroundColor = event.target.checked ? this.styles.style.mainIdentifyingColor : '';

  }



  // addUser(user) {
  //   const index = this.registerResponseModel.userGroups.indexOf(user);
  //   user.checked = !user.checked;
  //   if (user.checked) {
  //     this.users.push(user.id);
  //     this.usersToShow.push(user.id);
  //     this.allUsers.push(user.id);
  //   }
  //   else {
  //     let indexOfUsers = -1;
  //     for (let i = 0; i < this.users.length; i++) {
  //       if (this.users[i] == index + 1) {
  //         indexOfUsers = i;
  //         break;
  //       }
  //     }
  //     if (indexOfUsers !== -1) {
  //       this.users.splice(indexOfUsers, 1);
  //       this.usersToShow.splice(indexOfUsers, 1);
  //     }
  //   }

  //   if (this.users.length == 0) {
  //     this.noMediaUserType = false;
  //   }
  //   this.registerResponseModel.mediaUserModel.mediaUserTypes = this.allUsers;
  // }

  checkIfEmpty() {
    if (this.valForm.controls['email'].value.length === 0) {
      this.valForm.controls['email'].setValidators(Validators.required);
    }
    this.valForm.controls['email'].setValidators(CustomValidators.email);
  }

  openAddDropdown() {
    this.addDropdown = !this.addDropdown;
  }

  checkPassword() {
    if (this.valForm.controls['password'].value === this.valForm.controls['confirmPassword'].value) {
      this.confirmError = false;
    }

  }

  ifEmpty() {
    if (this.valForm.controls['oldPassword'].value != '') {
      this.showPw = true;
      this.enterPw = false;
    }
    if (this.valForm.controls['password'].value === '' && this.valForm.controls['oldPassword'].value != '') {
      this.oldPassword = true;
    }

    if (this.valForm.controls['oldPassword'].value == '' || this.valForm.controls['oldPassword'].value == null) {
      this.enterPw = false;
    }

    if (this.valForm.controls['oldPassword'].value == '' || this.valForm.controls['oldPassword'].value == null) {
      this.oldPassword = false;
    }
  }

  setConfirmError() {
    if (this.valForm.controls['password'].value !== this.valForm.controls['confirmPassword'].value) {
      this.confirmError = true;
    }
    if (this.valForm.controls['password'].value !== '' && this.valForm.controls['oldPassword'].value != '') {
      this.oldPassword = false;
    }
    if (this.valForm.controls['password'].value == '' && this.valForm.controls['oldPassword'].value != '') {
      this.oldPassword = true;
    }
    if (this.valForm.controls['password'].value !== '' && this.valForm.controls['oldPassword'].value == '') {
      this.enterPw = true;
    }

    // if (this.valForm.controls['password'].value === '') {
    //   this.confirmError = false;
    // }

  }

  update() {
    window.scrollTo(0, 0);
    this.mcService.update(this.registerResponseModel.mediaUserModel)
      .subscribe((response) => {

        },
        (error) => {
          //
        });
  }

  newsletterCheck() {
    this.registerResponseModel.mediaUserModel.receiveNewsletter = !this.registerResponseModel.mediaUserModel.receiveNewsletter;
  }

  toggleDropdown() {
    this.dropdown = !this.dropdown;
  }

  onSubmit(e) {
    e.stopPropagation();
    if (this.valForm.controls['oldPassword'].value == '' && this.valForm.controls['password'].value != '') {
      this.enterPw = true;
    }
    if (this.valForm.controls['oldPassword'].value == '' && this.valForm.controls['password'].value == '') {
      this.enterPw = false;
      this.oldPassword = false;
    }
    for (const c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }

    if (this.registerResponseModel.mediaUserModel.mediaUserTypes.length === 0) {
      this.userFilled = false;
    } else{
      this.userFilled = true;
    }

    if (this.valForm.valid && !this.oldPassword && !this.enterPw && this.userFilled) {
      this.update();
    }
  }

}
